<template>
  <div class='left_bottom'>
    <div class="city_title"></div>
    <div class="swiper_tit">
      <p>
        <span></span>
        一万方以上酒店接入率
      </p>
      <p>
        <span style="background:#3AFFE4"></span>
        A级酒店接入率
      </p>
      <p>
        <span style="background:#E9A5FF"></span>
        政府接待酒店接入率
      </p>
    </div>
    <div class="swiper swiper_hotel" v-if="list.length > 0">
      <div class="swiper-wrapper">
        <div v-for="(item, index) in list" class="swiper-slide" :key="index">
          <div class="row_name">{{ item.areaName }}</div>
          <div class="row_col">
            <p>
              <span>{{ item.typeThird.percent >= 100 ? (100).toFixed(2) : item.typeThird.percent }}%</span>
              <el-tooltip class="item" effect="dark"
                :content="`${item.typeThird.readyCount} / ${item.typeThird.totalCount}`" placement="bottom-start">
                <i :style="{ width: `${item.typeThird.percent >= 100 ? 100 : item.typeThird.percent}%` }"></i>
              </el-tooltip>
            </p>
            <p>
              <span>{{ item.typeSecond.percent >= 100 ? (100).toFixed(2) : item.typeSecond.percent }}%</span>
              <el-tooltip class="item" effect="dark"
                :content="`${item.typeSecond.readyCount} / ${item.typeSecond.totalCount}`" placement="bottom-start">
                <i
                  :style="{ background: '#3AFFE4', width: `${item.typeSecond.percent >= 100 ? 100 : item.typeSecond.percent}%` }"></i>
              </el-tooltip>
            </p>
            <p>
              <span>{{ item.typeFirst.percent >= 100 ? (100).toFixed(2) : item.typeFirst.percent }}%</span>
              <el-tooltip class="item" effect="dark"
                :content="`${item.typeFirst.readyCount} / ${item.typeFirst.totalCount}`" placement="bottom-start">
                <i
                  :style="{ background: '#E9A5FF', width: `${item.typeFirst.percent >= 100 ? 100 : item.typeFirst.percent}%` }"></i>
              </el-tooltip>
            </p>
          </div>
        </div>

      </div>
    </div>



  </div>
</template>

<script>
import AMap from 'AMap'
import { mapGetters } from 'vuex'
// import { getEnter } from '@/api'
import Swiper, { Autoplay } from 'swiper';
import 'swiper/swiper.scss';
Swiper.use([Autoplay])

export default {
  data() {
    return {
      list: []

    };
  },
  created() {
    // this.init()
  },
  mounted() {
    
  },
  watch: {
    list: {
      immediate: true,
      handler(newVal) {
        this.$nextTick(() => {
          if (newVal && newVal.length > 6) {
            new Swiper('.swiper_hotel', {
              autoplay: {
                delay: 0,
                stopOnLastSlide: false,
                disableOnInteraction: false,
                pauseOnMouseEnter: true
              },
              direction: 'vertical',
              slidesPerView: 'auto',
              loop: true,
              speed: 1500,
              observer:true,
              observeParents:true
            })
          }
          if (newVal && newVal.length <= 6) {
            new Swiper('.swiper_hotel', {
              direction: 'vertical',
              slidesPerView: 'auto',
              loop: false,
              speed: 1500,
              observer:true,
              observeParents:true
            })
          }
        })
        this.$nextTick(() => {
          let liArr = document.getElementsByClassName('row_name')
          let widthArr = 0
          for (let i = 0; i < liArr.length; i++) {
            widthArr = widthArr > liArr[i].clientWidth ? widthArr : liArr[i].clientWidth
          }
          for (let i = 0; i < liArr.length; i++) {
            liArr[i].style.width = `${widthArr}px`
          }
        })
      }

    }
  },
  computed: {
    ...mapGetters(['name'])
  },

  methods: {
    init(val) {
      // getEnter().then(res => {
        this.getName(val)
      // })
    },
    // 获取adcode对应的名称
    getName(enterData) {
      let district
      const _this = this
      AMap.plugin('AMap.DistrictSearch', function () {
        district = new AMap.DistrictSearch({
          subdistrict: 1,
          showbiz: false
        })
        district.search(_this.name, function (status, result) {
          if (status == 'complete') {
            let arr = []
            let list = []
            result.districtList[0].districtList.map(el => {
              arr.push({
                adcode: el.adcode,
                name: el.name
              })
              list.push(el.adcode)
            })
            _this.adcodeList = arr
            _this.adcodeArr = result.districtList[0].adcode
            _this.dealMsg(enterData)
          }
        })
      })
    },
    // 处理接口返回数据
    dealMsg(val) {
      val.map(el => {
        el.areaCount = el.areaCount.filter((item) => item.pcode === this.adcodeArr)
      })
      const arr = val
      let govEnterList = {}
      let aEnterList = {}
      let upEnterList = {}
      arr.map(el => {
        switch (el.type) {
          case 'wait':
            govEnterList = el
            break;
          case 'grade':
            aEnterList = el
            break;
          case 'area':
            upEnterList = el
            break;
        }
      })
      const arrs = govEnterList.areaCount.map(item => {
        const data = aEnterList.areaCount.find(i => i.adcode === item.adcode)
        return {
          adcode: item.adcode,
          areaName: item.areaName,
          typeFirst: {
            type: govEnterList.type,
            readyCount: item.readyCount ? item.readyCount : 0,
            totalCount: item.totalCount ? item.totalCount : 0,
            percent: item.totalCount ? ((item.readyCount / item.totalCount) * 100).toFixed(2) : 100
          },
          typeSecond: {
            type: aEnterList.type,
            readyCount: data.readyCount ? data.readyCount : 0,
            totalCount: data.totalCount ? data.totalCount : 0,
            percent: data.totalCount ? ((data.readyCount / data.totalCount) * 100).toFixed(2) : 100
          }
        }
      })
      arrs.map(el => {
        const data = upEnterList.areaCount.find(i => i.adcode === el.adcode)
        el.typeThird = {
          type: upEnterList.type,
          readyCount: data.readyCount ? data.readyCount : 0,
          totalCount: data.totalCount ? data.totalCount : 0,
          percent: data.totalCount ? ((data.readyCount / data.totalCount) * 100).toFixed(2) : 100
        }
      })
      arrs.sort((a, b) => {
        return a.adcode - b.adcode
      })
      this.list = arrs
      // this.initSwiper()
    },
    initSwiper() {
      this.$nextTick(() => {

        if (this.list && this.list.length > 6) {
          new Swiper('.swiper_hotel', {
            autoplay: {
              delay: 0,
              stopOnLastSlide: false,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            },
            direction: 'vertical',
            slidesPerView: 'auto',
            loop: true,
            speed: 1500
          })
        }
        if (this.list && this.list.length <= 6) {
          new Swiper('.swiper_hotel', {
            direction: 'vertical',
            slidesPerView: 'auto',
            // loop: true,
            speed: 1500
          })
        }
      })
      this.$nextTick(() => {
        let liArr = document.getElementsByClassName('row_name')
        let widthArr = 0
        for (let i = 0; i < liArr.length; i++) {
          widthArr = widthArr > liArr[i].clientWidth ? widthArr : liArr[i].clientWidth
        }
        for (let i = 0; i < liArr.length; i++) {
          liArr[i].style.width = `${widthArr}px`
        }
      })
    }

  }
};
</script>

<style scoped lang="scss">
.left_bottom {
  width: 609px;
  height: 307px;
  background: url("../../../../assets/city/leftbottom.png") no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  margin-right: 24px;

  .city_title {
    background: url("../../../../assets/city/enter_title.png") no-repeat;
    height: 33px;
    background-size: 100%;
  }

  .swiper_tit {
    padding: 0 21px 0 24px;
    color: #fff;
    display: flex;
    align-items: center;
    height: 50px;

    p {
      box-sizing: border-box;
      width: 33.3%;
      font-size: 12px;
      color: #fdfdfd;
      white-space: nowrap;
      text-overflow: ellipsis;
      background: none;
      padding-right: 10px;

      span {
        display: inline-block;
        position: static;
        margin-right: 2px;
        width: 10px;
        height: 10px;
        background: #28d9fa;
        border-radius: 2px;
      }
    }

    &::before {
      content: '';
      width: 60px;
      padding-right: 10px;
    }
  }

  .swiper_hotel {
    overflow: hidden;
    height: 180px;
    padding: 1px 21px 0 24px;
    .swiper-wrapper{
      flex-direction: column;
    }

    .swiper-slide {
      height: 50px;
      color: white;
      display: flex;
      align-items: center;
      font-size: 14px;

      .row_name {
        box-sizing: border-box;
        width: 60px;
        flex-shrink: 0;
        font-size: 14px;
        color: #999da8;
        white-space: nowrap;
        justify-content: center;
        padding-right: 15px;
        height: 100%;
        display: flex;
        align-items: center;
        border-right: 1px solid #596185;
      }

      .row_col {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          width: 33.4%;
          height: 10px;
          line-height: 1;
          background: rgba(255, 255, 255, 0.2);
          border-radius: 0px 2px 2px 0px;
          margin-right: 16px;
          position: relative;

          span {
            position: absolute;
            top: -18px;
            font-size: 12px;
            color: #ffffff;
            right: 0;
            opacity: 1;
          }

          i {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            display: inline-block;
            height: 11px;
            background: #28d9fa;
            border-radius: 0px 2px 2px 0px;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

    }

  }

}
</style>