<template>
  <div class='center_bottom'>
    <div class="city_title"></div>
    <div class="substance">
      <el-row class="citycaption">
        <el-col :span="8">酒店名称</el-col>
        <el-col :span="9">风险内容</el-col>
        <!-- <el-col :span="3">房间</el-col> -->
        <el-col :span="7">推送时间</el-col>
      </el-row>
      <div class="swiper swiper_city" v-if="rankList.length>0">
        <div class="swiper-wrapper">
          <el-row v-for="(el, index) in rankList" class="swiper-slide" :key="index">
            <el-col :span="8">{{el.siteName}}</el-col>
            <el-col :span="9">{{el.message}}</el-col>
            <!-- <el-col :span="3">{{el.hotelRoomName ? el.hotelRoomName : el.roomName}}</el-col> -->
            <el-col :span="7">{{el.time}}</el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAlarm } from '@/api'
import Swiper, { Autoplay } from 'swiper';
import 'swiper/swiper.scss';
Swiper.use([Autoplay])

export default {
  data () {
    return {
      rankList: []
    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  methods: {
    init () {
      getAlarm().then(res => {
        const arr = res.data.sort((a, b) => {
          return a.date > b.date ? -1 : 1
        })
        arr.map(el => {
          if (el.date.trim().split(" ")[1] === '00:00:00') {
            el.time = el.date.trim().split(" ")[0]
          } else {
            el.time = el.date
          }
        })
        this.rankList = arr

        this.initSwiper()

      })
    },
    initSwiper () {
      this.$nextTick(() => {
        if (this.rankList && this.rankList.length > 6) {
          new Swiper('.swiper_city', {
            autoplay: {
              delay: 0,
              stopOnLastSlide: false,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            },
            direction: 'vertical',
            slidesPerView: 'auto',
            loop: true,
            speed: 1500
          })
        }
        if (this.rankList && this.rankList.length <= 6) {
          new Swiper('.swiper_city', {
            direction: 'vertical',
            slidesPerView: 'auto',
            // loop: true,
            speed: 1500
          })
        }
      })
    }
  }
};
</script>

<style scoped lang="scss">
.center_bottom {
  // width: 839px;
  flex: 1;
  width: 608px;
  height: 307px;
  background: url("../../../../assets/city/centerbottom.png") no-repeat;
  background-size: 100% 100%;
  .city_title {
    background: url("../../../../assets/city/risk_title.png") no-repeat;
    height: 33px;
    background-size: 100%;
  }
  .citycaption {
    color: #28d4f5;
    font-size: 16px;
    width: 100%;
    margin-top: 33px;
    padding-left: 32px;
    font-weight: 500;
  }
  .substance {
    box-sizing: border-box;
    padding: 0 27px 38px 10px;
    width: 100%;
  }
}

.swiper_city {
  overflow: hidden;
  height: 160px;
  padding-left: 32px;
  margin-top: 15px;
  .swiper-slide {
    height: 30px;
    padding: 10px;
    color: white;
    display: flex;
    align-items: center;
    font-size: 14px;
    .el-col-8 {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .swiper-slide:nth-child(2n) {
    background: rgba(68, 202, 255, 0.4);
  }
}
</style>
