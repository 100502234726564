<template>
  <div class='left_top'>
    <div class="city_title"></div>
    <div class="substance">
      <el-row class="citycaption">
        <el-col :span="8">区县名称</el-col>
        <el-col :span="9">杯具洗消在线率</el-col>
        <el-col :span="7">洁具清洁使用率</el-col>
      </el-row>
      <div class="list_box">
        <el-row v-for="(el, index) in list" :key="index" class="row">
          <el-col :span="8">{{ el.areaName }}</el-col>
          <el-col :span="9" class="readerRate">
            <span> {{ el.cupRate }}</span>
            <span v-if="el.cupRateIcon == true">
              <img src="~@/assets/city/warning.png" alt="" srcset="">
            </span>
          </el-col>
          <el-col :span="7" class="readerRate">
            <span>{{ el.readerRate }}</span>
            <span v-if="el.readerRateIcon == true">
              <img src="~@/assets/city/warning.png" alt="" srcset="">
            </span>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getStatisticsByParentAreaCode } from '@/api'
export default {
  data() {
    return {
      list: []
    };
  },
  created() {
    this.init()
  },
  mounted() {

  },
  computed: {
    ...mapGetters(['name'])
  },
  methods: {
    init() {
      const areaCode = localStorage.getItem('areaCode')
      const yesterday = this.$moment().subtract(1, 'days').format('YYYY-MM-DD')
      getStatisticsByParentAreaCode(['7,9', areaCode, '2024-07-15', yesterday]).then(res => {
        if (res.code === 0 && res.message === 'success') {
          res.data.map(i => {

            i.cupRate    = i.areaDataMap[7] ? (i.areaDataMap[7].statRate * 100 > 100 ? 100 : i.areaDataMap[7].statRate * 100).toFixed(0) + '%' : '-'
            i.readerRate = i.areaDataMap[9] ? (i.areaDataMap[9].statRate * 100 > 100 ? 100 : i.areaDataMap[9].statRate * 100).toFixed(0) + '%' : '-';
            i.readerRateIcon = i.areaDataMap[9] && i.areaDataMap[9].statRate * 100 > 100 ? true : false
            i.cupRateIcon=i.areaDataMap[7] && i.areaDataMap[7].statRate * 100 > 100 ? true : false

          })
          this.list = res.data
        }
      })
    }

  }
};
</script>

<style scoped lang="scss">
* {
  font-family: SourceHanSansCNNormal;
}

.left_top {
  width: 609px;
  height: 629px;
  background: url("../../../../assets/city/lefttop.png") no-repeat;
  background-size: 100% 100%;

  .city_title {
    background: url("../../../../assets/city/onlineRateTit.png") no-repeat;
    height: 33px;
    background-size: 100%;
  }

  .substance {
    width: 100%;
    padding: 0 24px 0 24px;
    box-sizing: border-box;
  }

  .citycaption {
    color: #28d4f5;
    font-size: 16px;
    width: 100%;
    padding: 20px 10px;
    font-weight: 500;
  }

  .list_box {
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: inherit;

    .row {
      padding: 0 10px;
      height: 20%;
      color: white;
      display: flex;
      align-items: center;
      font-size: 14px;

      .el-col-8 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .readerRate {
        display: flex;
        span:first-child{
          min-width: 50px;
        }

        img {
          width: 14px;
          vertical-align: top;
          margin-left: 5px;
        }
      }
    }

    .row:nth-child(2n) {
      background: rgba(68, 202, 255, 0.4);
    }
  }



}
</style>
