<template>
  <div class='city_map'>
    <div className="back" @click="ehartsMapBack" v-if="backNumber !== 0">
      <i class="el-icon-back" style="color:#fff"></i>
    </div>

    <div id="cityMap"></div>
  </div>
</template>

<script>

import {  getAreaList } from '@/api'
import AMap from 'AMap'
import AMapUI from 'AMapUI'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      echartsMap: null,
      map: null,
      geoJsonData: null,
      cityName: '浙江',
      mapData: [],
      cityCode: 330000,
      level: 'province',
      district: null,
      upCityName: '浙江',
      upLevel: 'province',
      upCode: 330000,
      city: [],
      backNumber: 0,
      hotelList: [],
      enterList: [],
      cityArea: []
    };
  },
  created () {

  },
  mounted () {
    // this.initList()
  },
  computed: {
    ...mapGetters(['name'])
  },
  methods: {
    // 获取初始数据
    initList (val) {
      // getAreaSite().then(res => {
      //   const city = res.data.filter(el => el.type === 1)
      //   this.city = city
      //   this.cityArea = res.data
      //   this.enterList = val
      //   this.initMap()
      // })
      getAreaList().then(res => {
        this.hotelList = res.data
        const city = res.data.filter(el => el.type === 1)
        this.city = city
        this.cityArea = res.data
        this.enterList = val
        this.initMap()
      })
    },
    // 初始化地图
    initMap () {
      let echartsMap = this.echartsMap
      let _this = this
      let map = this.map
      let district = this.district
      echartsMap = this.$echarts.init(document.getElementById('cityMap'));
      echartsMap.on('click', this.echartsMapClick);
      AMap.plugin('AMap.DistrictSearch', function () {
        district = new AMap.DistrictSearch({
          // 关键字对应的行政区级别，country表示国家
          // level: 'country',
          //  显示下级行政区级数，1表示返回下一级行政区
          subdistrict: 1,
          showbiz: false
        })

        // 搜索所有省/直辖市信息
        district.search(_this.name, function (status, result) {
          // 查询成功时，result即为对应的行政区信息
          if (status == 'complete') {
            // console.log('result :>> ', result);
            _this.cityName = result.districtList[0].name
            _this.cityCode = result.districtList[0].adcode
            _this.level = result.districtList[0].level
            _this.upCityName = result.districtList[0].name
            _this.upLevel = result.districtList[0].level
            _this.upCode = result.districtList[0].adcode
            _this.getData(result.districtList[0], '', result.districtList[0].adcode);
          }
        })
      })
      map = new AMap.Map('container', {
        resizeEnable: true,
        center: [116.30946, 39.937629],
        zoom: 3
      });
      this.echartsMap = echartsMap
      this.map = map
      this.district = district
    },
    getData (data, level, adcode) {
      var subList = data.districtList;
      let geoJsonData = this.geoJsonData
      let cityName = this.cityName
      let mapData = this.mapData
      if (subList) {
        var curlevel = subList[0].level;
        if (curlevel === 'street') {
          if (geoJsonData) {
            let mapJsonList = geoJsonData.features;
            let mapJson = {};
            for (let i in mapJsonList) {
              if (mapJsonList[i].properties.name == cityName) {
                mapJson.type = "FeatureCollection";
                mapJson.features = [].concat(mapJsonList[i]);
              }
            }
            mapData = [];
            let cityArea = this.cityArea
            let enterList = this.enterList
            mapData.push({ name: cityName, value: Math.random() * 100, level: curlevel, cityCode: adcode });
            const arr = cityArea.filter(el => {
              if (el.name) {
                return el.name.indexOf(cityName) != -1
              }
            })
            mapData.map(el => {
              arr.map(item => {
                if (el.cityCode === item.adcode) {
                  el.count = item.count
                  el.siteList = item.siteList
                  el.haveDetection = item.haveDetection
                  el.haveDishcloth = item.haveDishcloth
                  el.haveCentralization = item.haveCentralization
                }
              })
            })
            enterList.map(el => {
              el.areaCount.map((item) => {
                mapData.map(item1 => {
                  if (item1.cityCode === item.adcode) {
                    item1[el.type] = {
                      readyCount: item.readyCount ? item.readyCount : 0,
                      totalCount: item.totalCount ? item.totalCount : 0
                    }
                  }
                })
              })
            })
            this.mapData = mapData
            this.loadMap(cityName, mapJson)
          }
          return
        }
        mapData = []
        for (var i = 0, l = subList.length; i < l; i++) {
          var name = subList[i].name;
          var cityCode = subList[i].adcode;
          mapData.push({
            name: name,
            value: Math.random() * 100,
            cityCode: cityCode,
            level: curlevel
          });
        }
        let city = this.city
        let cityArea = this.cityArea
        const enterList = this.enterList
        if (curlevel === 'city') {
          mapData.map(el => {
            city.map(item => {
              if (el.cityCode === item.adcode) {
                el.count = item.count
                el.haveDetection = item.haveDetection
                el.haveDishcloth = item.haveDishcloth
                el.haveCentralization = item.haveCentralization
              }
            })
          })
          enterList.map(el => {
            el.areaCount.map((item) => {
              mapData.map(item1 => {
                if (item1.cityCode === item.adcode) {
                  item1[el.type] = {
                    readyCount: item.readyCount ? item.readyCount : 0,
                    totalCount: item.totalCount ? item.totalCount : 0
                  }
                }
              })
            })
          })
        } else {
          const arr = cityArea.filter(el => {
            if (el.name) {
              if (cityName === '杭州市') {
                return el.name.indexOf('杭州') != -1
              } else {
                return el.name.indexOf(cityName) != -1
              }

            }
          })
          mapData.map(el => {
            arr.map(item => {
              if (el.cityCode === item.adcode) {
                el.count = item.count
                el.haveDetection = item.haveDetection
                el.haveDishcloth = item.haveDishcloth
                el.haveCentralization = item.haveCentralization
              }
            })
          })
        }
        enterList.map(el => {
          el.areaCount.map((item) => {
            mapData.map(item1 => {
              if (item1.cityCode === item.adcode) {
                item1[el.type] = {
                  readyCount: item.readyCount ? item.readyCount : 0,
                  totalCount: item.totalCount ? item.totalCount : 0
                }
              }
            })
          })
        })
        this.mapData = mapData
        this.loadMapData(adcode);
      }
    },
    loadMap (mapName, data) {
      let echartsMap = this.echartsMap
      let mapData = this.mapData
      let hotelList = this.hotelList
      const dotList = []
      if (mapData[0].level === 'street') {
        const arr = hotelList.filter(el => el.adcode === mapData[0].cityCode)
        if (arr.length > 0) {
          if (arr[0].siteList && arr[0].siteList.length > 0) {
            arr[0].siteList.map(el => {
              dotList.push({
                name: el.sitename,
                value: [el.longitude, el.latitude],
                phone: el.phone,
                roomQuantity: el.roomQuantity,
                cameraQuantity: el.cameraQuantity,
                boardQuantity: el.boardQuantity
              })
            })
          }
        }
      }
      const _this = this
      if (data) {
        this.$echarts.registerMap(mapName, data);
        var option = {
          tooltip: {
            show: true,
            trigger: 'item',
            position: function (pos, params, dom, rect, size) {
              // 鼠标在左侧时 tooltip 显示到右侧，鼠标在右侧时 tooltip 显示到左侧。
              var obj = { top: pos[1] };
              obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
              return obj;
            },
            formatter: function (params) {
              if (params.data.level === 'street') return
              let number = 0
              if (params.data.count) {
                number = params.data.count
              }
              setTimeout(() => {
                _this.drawPie(number)
              }, 100)
              return `
                <div class="maptooltip">
          <h3>${params.data.name}</h3>
          <p class="headline">
          <i></i>
          <span>运行情况</span>
           </p>
          <div class="tooltipmsg">
            <div id="tooltippie"></div>
            <div class="total">
              <p>杯具洗消：<span>${params.data.haveDetection ? params.data.haveDetection : 0}</span></p>
              <p>洁具清洁：<span>${params.data.haveDishcloth ? params.data.haveDishcloth : 0}</span></p>
              <p>布草更换：<span>${params.data.haveCentralization ? params.data.haveCentralization : 0}</span></p>
            </div>
          </div>
             <p class="headline">
          <i></i>
          <span>接入情况</span>
           </p>
           <div class="tooltipstatus"><p>一万方以上酒店：</p><p><span>${params.data.area ? params.data.area.readyCount : 0}</span>/${params.data.area ? params.data.area.totalCount : 0}</p></div>
           <div class="tooltipstatus"><p>A级酒店：</p><p><span>${params.data.grade ? params.data.grade.readyCount : 0}</span>/${params.data.grade ? params.data.grade.totalCount : 0}</p></div>
           <div class="tooltipstatus"><p>政府接待酒店：</p><p><span>${params.data.wait ? params.data.wait.readyCount : 0}</span>/${params.data.wait ? params.data.wait.totalCount : 0}</p></div>
          </div >
              `
            },
            backgroundColor: 'rgba(14,33,60, .9)',
            borderColor: 'rgba(40, 86, 148, .5)',
            padding: 0
          },
          /*backgroundColor: '#000f1e',*/
          geo: {
            map: mapName,
            aspectScale: 0.8,
            layoutCenter: ["50%", "50%"], //地图位置
            layoutSize: '100%',
            itemStyle: {
              normal: {
                shadowColor: '#1970B9',
                shadowOffsetX: 3,
                shadowOffsetY: 10,
                opacity: 0.8,
              },
              emphasis: {
                areaColor: '#1970B9',

              }
            },

          },
          series: [
            // 常规地图
            {
              type: 'map',
              mapType: mapName,
              // roam: 'scale',、
              aspectScale: 0.8,
              layoutCenter: ["50%", "50%"], //地图位置
              layoutSize: '100%',
              zoom: 1, //当前视角的缩放比例
              scaleLimit: { //滚轮缩放的极限控制
                min: 1,
                max: 2
              },
              itemStyle: {
                normal: {
                  areaColor: '#10265b',
                  borderColor: '#29DDFE',
                  borderWidth: 1,
                  opacity: 1,
                  // shadowColor: 'rgba(0, 0, 0, 0.5)',
                  // shadowBlur: 10
                },
                emphasis: {
                  areaColor: '#16376C',
                  label: {
                    color: "#fff"
                  },
                  // opacity: 0.5,


                }
              },
              label: {
                show: true,
                color: 'white',
                formatter: function (params) {
                  let number = 0
                  if (params.data.count) {
                    number = params.data.count
                  }
                  return ('{count|' + number + '}\n{name|' + params.data.name + '}')
                },
                rich: {
                  count: {
                    color: '#FACC14',
                    fontSize: '14'
                  },
                  name: {
                    color: 'white',
                    fontSize: '12'
                  },
                  box:{
                    width:7,
                    height:20,
                    borderRadius: 2,
                    backgroundColor:{
                      type: 'linear',
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [{
                        offset: 0, color: '#29d9f4' // 0% 处的颜色
                      }, {
                        offset: 1, color:"transparent",// 100% 处的颜色
                      }],
                      globalCoord: false
                    }
                  },
                  cy:{
                    width:4,
                    height:4,
                    borderRadius: 2,
                    backgroundColor:{
                      type: 'radial',
                      x: 0.5,
                      y: 0.5,
                      r: 0.5,
                      colorStops: [{
                        offset: 0, color: '#fff' // 0% 处的颜色
                      }, {
                        offset: 1, color:"#29DDFE",// 100% 处的颜色
                      }],
                      globalCoord: false
                    }
                  }
                }
              },
              data: this.mapData,
              select: { // 地图选中区域样式
                label: { // 选中区域的label(文字)样式
                  color: '#fff'
                },
                itemStyle: {// 选中区域的默认样式
                  areaColor: '#10265B'
                },
              },
            },
            // 区域散点图
            {
              type: 'effectScatter',
              coordinateSystem: 'geo',
              // showEffectOn: 'emphasis',
              zlevel: 2,
              symbolSize: 8,
              rippleEffect: { //坐标点动画
                period: 3,
                scale: 5,
                // brushType: 'stroke'
              },
              label: {
                normal: {
                  show: false,
                  position: 'top',
                  formatter: '{b}',
                  color: '#b3e2f2',
                  // fontWeight: "",
                  fontSize: 12
                }
              },


              itemStyle: { //坐标点颜色
                normal: {
                  show: true,
                  color: '#87CEFA',
                  shadowBlur: 20,
                  shadowColor: '#fff'
                },
                emphasis: {
                  areaColor: '#f00',
                  focus: 'series'
                }
              },
              data: dotList,
              tooltip: {
                show: true,
                formatter: function (params) {
                  return `
                <div class="tooltip">
          <div class="city"><i></i>${params.name}</div>
          <div class="content">
            <div>
              <i></i>
              <p>
                摄像头：
                <span> ${params.data.cameraQuantity} </span>
                个
              </p>
            </div>
            <div>
              <i></i>
              <p>
                插座：
                <span> ${params.data.boardQuantity} </span>
                个
              </p>
            </div>
            <div>
              <i></i>
              <p>
                消毒间：
                <span> ${params.data.roomQuantity} </span>
                个
              </p>
            </div>
          </div>
        </div>
              `
                  // position: [10, 10]
                }
              }
            },
          ]
        }
        echartsMap.setOption(option);
        this.echartsMap = echartsMap
      }
    },
    loadMapData (areaCode) {
      let map = this.map
      // let geoJsonData = this.geoJsonData
      // let cityName = this.cityName
      AMapUI.loadUI(['geo/DistrictExplorer'], DistrictExplorer => {
        //创建一个实例
        var districtExplorer = window.districtExplorer = new DistrictExplorer({
          eventSupport: true, //打开事件支持
          map: map
        });

        districtExplorer.loadAreaNode(areaCode, (error, areaNode) => {
          // debugger
          if (error) {
            console.error(error);
            return;
          }
          let mapJson = {};
          mapJson.type = "FeatureCollection";
          mapJson.features = areaNode.getSubFeatures();
          this.loadMap('浙江', mapJson);
          this.geoJsonData = mapJson;
        });
      });
    },
    // 地图点击事件
    echartsMapClick (params) {//地图点击事件
      if (params && params.data.level == 'street') return;
      let cityName = this.cityName
      let cityCode = this.cityCode
      let district = this.district
      let level = this.level
      let backNumber = this.backNumber
      this.upCityName = cityName,
        this.upCode = cityCode,
        this.upLevel = level

      //清除地图上所有覆盖物
      cityName = params.data.name;
      cityCode = params.data.cityCode;
      level = params.data.level
      district.setLevel(params.data.level); //行政区级别
      district.setExtensions('all');
      //行政区查询
      //按照adcode进行查询可以保证数据返回的唯一性
      district.search(cityCode, (status, result) => {
        if (status === 'complete') {
          this.getData(result.districtList[0], params.data.level, cityCode);
        }
      });
      backNumber = backNumber + 1
      this.cityName = cityName
      this.cityCode = cityCode
      this.district = district
      this.level = level
      this.backNumber = backNumber
    },
    // 地图返回事件
    ehartsMapBack () {
      let cityName = this.upCityName
      let cityCode = this.upCode
      let district = this.district
      let backNumber = this.backNumber
      //清除地图上所有覆盖物
      district.setLevel(this.upLevel); //行政区级别
      district.setExtensions('all');
      //行政区查询
      //按照adcode进行查询可以保证数据返回的唯一性
      district.search(cityName, (status, result) => {
        if (status === 'complete') {
          this.getData(result.districtList[0], this.upLevel, cityCode);
        }
      });
      backNumber = backNumber - 1
      // this.setState({
      //   cityName, cityCode, district, backNumber,
      //   upCityName: '浙江',
      //   upCode: 330000,
      //   upLevel: ''
      // })
      this.cityName = cityName
      this.cityCode = cityCode
      this.district = district
      this.backNumber = backNumber
      this.upCityName = '浙江'
      this.upCode = 330000
      this.upLevel = ''
    },
    // 绘制饼图
    drawPie (count) {
      const chart = this.$echarts.init(document.getElementById('tooltippie'))
      // 绘制图表
      const option = {
        // backgroundColor: '#142468',
        series: [{
          type: 'pie',
          zlevel: 1,
          silent: true,
          radius: ['92%', '97%'],
          hoverAnimation: false,
          color: "#1F3267",
          // animation:false,    //charts3 no
          label: {
            normal: {
              show: true,
              position: 'center',
              color: '#9A9EBA',
              formatter: '{total|' + count + '}' + '\n\r' + '{active|酒店}',
              emphasis: {//中间文字显示
                show: true,
              },
              rich: {
                total: {
                  fontSize: 20,
                  fontFamily: "微软雅黑",
                  color: '#FFFFFF'
                },
                active: {
                  fontFamily: "微软雅黑",
                  fontSize: 16,
                  color: '#999DA8'
                },
              }
            },

          },
          labelLine: {
            normal: {
              show: false
            }
          },
          data: [1]
        },
        {
          type: 'pie',
          zlevel: 2,
          silent: true,
          radius: ['70%', '80%'],
          label: {
            normal: {
              show: false
            },
          },
          labelLine: {
            normal: {
              show: false
            }
          },
          data: this._pie3()
        },
        {
          type: 'pie',
          zlevel: 3,
          silent: true,
          radius: ['65%', '60%'],
          label: {
            normal: {
              show: false
            },
          },
          labelLine: {
            normal: {
              show: false
            }
          },
          data: this._pie2()
        },
        ]
      }
      option && chart.setOption(option)
      window.addEventListener("resize", () => {
        chart.resize();
      })
    },
    _pie3 () {
      let dataArr = [];
      for (var i = 0; i < 100; i++) {
        if (i % 8 === 0) {
          dataArr.push({
            name: (i + 1).toString(),
            value: 200,
            itemStyle: {
              normal: {
                color: "#2AA2C0",
                borderWidth: 0,
                borderColor: "rgba(0,0,0,0)"
              }
            }
          })
        } else {
          dataArr.push({
            name: (i + 1).toString(),
            value: 5,
            itemStyle: {
              normal: {
                color: "rgba(0,0,0,0)",
                borderWidth: 0,
                borderColor: "rgba(0,0,0,0)"
              }
            }
          })
        }

      }
      return dataArr

    },
    _pie2 () {
      let dataArr = [];
      for (var i = 0; i < 100; i++) {
        if (i % 2 === 0) {
          dataArr.push({
            name: (i + 1).toString(),
            value: 10,
            itemStyle: {
              normal: {
                color: "rgba(42,162,192,0.5)",
                borderWidth: 0,
                borderColor: "rgba(0,0,0,0)"
              }
            }
          })
        } else {
          dataArr.push({
            name: (i + 1).toString(),
            value: 10,
            itemStyle: {
              normal: {
                color: "rgba(0,0,0,0)",
                borderWidth: 0,
                borderColor: "rgba(0,0,0,0)"
              }
            }
          })
        }

      }
      return dataArr

    }
  }
};
</script>

<style scoped lang="scss">
.city_map {
  flex: 1;
  height: 100%;
  position: relative;
  #cityMap {
    width: 95%;
    height: 97%;
  }
  .back {
    position: absolute;
    left: 40px;
    top: 10px;
    z-index: 1000;
    color: white;
    font-size: 16px;
    font-weight: 800;
    cursor: pointer;
  }
  .el-icon-back {
    font-size: 24px;
    cursor: pointer;
  }
}
</style>
