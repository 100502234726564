<template>
  <div class='center_top'>
    <div class="box">
      <div class="card">
        酒店数量 <countTo :startVal='0' :endVal='count.siteCount' :duration='1000' separator="" />
      </div>
      <div class="card">
        覆盖区县 <countTo :startVal='0' :endVal='count.areaCount' :duration='1000' separator="" />
      </div>
      <div class="card">
        推送事件 <countTo :startVal='0' :endVal='count.riskTotalCount' :duration='1000' separator="" />
      </div>
    </div>
    <Map ref="mapData"/>
  </div>
</template>

<script>
import countTo from 'vue-count-to';
// import { getCountStatistic } from '@/api'
import Map from './map.vue'
// import AMap from 'AMap'
import { mapGetters } from 'vuex'

export default {
  components: { countTo, Map },
  // components:{Map},
  data () {
    return {
      count: {
        siteCount:null,
        areaCount:null,
        riskTotalCount:null,
      },
      upTotal: {},
      aTotal: {},
      govTotal: {}
    };
  },
  created () {
    // this.init()
  },
  mounted () {

  },
  computed: {
    ...mapGetters(['name'])
  },
  methods: {
    init (val) {
      this.count=val
      // // 获取酒店分布
      // getCountStatistic().then(res => {
      //   this.count = res.data
      //   // console.log(res.data);
      // })
      // // this.getName(enterData)
    },
    initMap(val){
      this.$refs.mapData.initList(val)
    }
    // getName (enterData) {
    //   let district
    //   const _this = this
    //   AMap.plugin('AMap.DistrictSearch', function () {
    //     district = new AMap.DistrictSearch({
    //       subdistrict: 1,
    //       showbiz: false
    //     })
    //     district.search(_this.name, function (status, result) {
    //       if (status == 'complete') {
    //         let arr = []
    //         let list = []
    //         result.districtList[0].districtList.map(el => {
    //           arr.push({
    //             adcode: el.adcode,
    //             name: el.name
    //           })
    //           list.push(el.adcode)
    //         })
    //         _this.adcodeList = arr
    //         _this.adcodeArr = list
    //         _this.dealMsg(enterData)
    //       }
    //     })
    //   })
    // },
    // // getEnterTotel (enterData) {
    // //   getEnter().then(res => {
    //     this.dealMsg(res.data)
    //   })
    // },
    // 处理接口返回数据
    // dealMsg (val) {
    //   console.log(val,'kkk');
    //   val.map(el => {
    //     el.areaCount = el.areaCount.filter((item) => {
    //       return this.adcodeArr.includes(item.adcode);
    //     })
    //   })
    //   val.map(el => {
    //     el.areaCount.map(item => {
    //       this.adcodeList.map(single => {
    //         if (item.adcode === single.adcode) {
    //           item.name = single.name
    //         }
    //       })
    //     })
    //   })
    //   const arr = val
    //   // console.log('arr :>> ', arr);
    //   arr.map(el => {
    //     el.readyCount = el.areaCount.reduce((prev, next) => {
    //       return prev + next.readyCount;
    //     }, 0);
    //     el.totalCount = el.areaCount.reduce((prev, next) => {
    //       return prev + next.totalCount;
    //     }, 0);
    //     switch (el.type) {
    //       case 'wait':
    //         this.govTotal = el
    //         break;
    //       case 'grade':
    //         this.aTotal = el
    //         break;
    //       case 'area':
    //         this.upTotal = el
    //         break;
    //     }
    //   })
    //   // this.list = arrs
    // }
  }
};
</script>

<style scoped lang="scss">
.center_top {
  flex: 1;
  height: 629px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 24px 0;
  .box{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    .card{
      width:170px;
      height:70px;
      background: url('~@/assets/city/centerCard.png') no-repeat;
      background-size:100% 100%;
      color: #ffffff;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 0 10px;
      span{
        font-size: 32px;
        font-weight: bold;
        color: #3AFFE4;
      }
    }
  }
  // .amount {
  //   > div {
  //     // position: relative;
  //     margin-top: 51px;
  //     div {
  //       width: 112px;
  //       height: 77px;
  //       background: url("../../../../assets/city/map_bg.png") no-repeat;
  //       background-size: 100% 100%;
  //       font-size: 36px;
  //       color: #3affe4;
  //       font-weight: 600;
  //       text-align: center;
  //       padding-top: 1px;
  //       > span {
  //         display: block;
  //         margin-top: 18px;
  //       }
  //     }

  //     p {
  //       width: 100%;
  //       font-size: 16px;
  //       color: #ffffff;
  //       // position: absolute;
  //       // margin: 0 auto;
  //       text-align: center;
  //       // bottom: 0;
  //     }
  //   }
  //   > div:first-child {
  //     margin-top: 15px;
  //   }
  // }
  // .amount:last-child {
  //   > div {
  //     div {
  //       font-size: 32px;
  //       white-space: nowrap;
  //       span {
  //         display: inline-block;
  //         margin-top: 18px;
  //         margin-left: -9px;
  //       }
  //       .countright {
  //         color: rgba(255, 255, 255, 0.5);
  //         font-size: 28px;
  //         margin-left: 0;
  //       }
  //     }
  //   }
  // }
}
</style>
