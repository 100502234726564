<template>
  <div class="chart">
    <div class="item">
      <div id="governReception">
      </div>
      <div class="num">
        <div><span>{{ governReceptionData.readyCount }}</span>/{{ governReceptionData.totalCount }}</div>
        <div>政府接待酒店</div>
      </div>
    </div>
    <div class="item">
      <div id="ARated"></div>
      <div class="num">
        <div><span>{{ ARatedData.readyCount }}</span>/{{ ARatedData.totalCount }}</div>
        <div>A级酒店</div>
      </div>
    </div>
    <div class="item">
      <div id="Huge"></div>
      <div class="num">
        <div><span>{{ HugeData.readyCount }}</span>/{{ HugeData.totalCount }}</div>
        <div>一万方以上酒店</div>
      </div>
    </div>


  </div>
</template>
<script>
// import { getEnter } from '@/api'
import AMap from 'AMap'
import { mapGetters } from 'vuex'


export default {
  data() {
    return {
      governReceptionData: {},
      ARatedData: {},
      HugeData: {},
      governRate: null,
      ARate: null,
      HugeRate: null,

    }
  },
  computed: {
    ...mapGetters(['name'])

  },
  created() {
  },
  mounted() {
    // this.init()

  },
  methods: {
    init(val) {
      const areaCode =localStorage.getItem('areaCode')
      if (areaCode != '330000') {
            const arr = val.map(i => {
              i.areaCount = i.areaCount.filter(e => { return e.adcode == areaCode })[0]
              return i
            })
            arr.map(el => {
              switch (el.type) {
                case 'wait':
                  console.log(1);
                  this.governReceptionData = el.areaCount
                  this.governRate = ((el.areaCount.readyCount / el.areaCount.totalCount) * 100).toFixed(2)
                  break;
                case 'grade':
                  this.ARatedData = el.areaCount
                  this.ARate = ((el.areaCount.readyCount / el.areaCount.totalCount) * 100).toFixed(2)
                  break;
                case 'area':
                  this.HugeData = el.areaCount
                  this.HugeRate = ((el.areaCount.readyCount / el.areaCount.totalCount) * 100).toFixed(2)
                  break;
              }
              this.drawPie()
              this.drawPieArate()
              this.drawPieHugerate()
            })
          }else{
            this.getName(val)
          }
    },
    getName(enterData) {
      let district
      const _this = this
      AMap.plugin('AMap.DistrictSearch', function () {
        district = new AMap.DistrictSearch({
          subdistrict: 1,
          showbiz: false
        })
        district.search(_this.name, function (status, result) {
          if (status == 'complete') {
            let arr = []
            let list = []
            result.districtList[0].districtList.map(el => {
              arr.push({
                adcode: el.adcode,
                name: el.name
              })
              list.push(el.adcode)
            })
            _this.adcodeList = arr
            _this.adcodeArr = list
            _this.dealMsg(enterData)
          }
        })
      })
    },
    dealMsg(val) {
      val.map(el => {
        el.areaCount = el.areaCount.filter((item) => {
          return this.adcodeArr.includes(item.adcode);
        })
      })
      val.map(el => {
        el.areaCount.map(item => {
          this.adcodeList.map(single => {
            if (item.adcode === single.adcode) {
              item.name = single.name
            }
          })
        })
      })
      const arr = val
      arr.map(el => {
        el.readyCount = el.areaCount.reduce((prev, next) => {
          return prev + next.readyCount;
        }, 0);
        el.totalCount = el.areaCount.reduce((prev, next) => {
          return prev + next.totalCount;
        }, 0);
        switch (el.type) {
          case 'wait':
            this.governReceptionData = el
            this.governRate = ((el.readyCount / el.totalCount) * 100).toFixed(2)
            break;
          case 'grade':
            this.ARatedData = el
            this.ARate = ((el.readyCount / el.totalCount) * 100).toFixed(2)
            break;
          case 'area':
            this.HugeData = el
            this.HugeRate = ((el.readyCount / el.totalCount) * 100).toFixed(2)
            break;
        }
      })
      this.drawPie()
      this.drawPieArate()
      this.drawPieHugerate()
    },
    drawPie() {
      const chart = this.$echarts.init(document.getElementById('governReception'))
      // 绘制图表
      const option = {
        title: [{
          text: `${this.governRate}%`,
          x: '43%',
          y: '40%',
          textAlign: 'center',
          textStyle: {
            fontSize: '16',
            fontWeight: '400',
            color: '#FFFFFF',
            textAlign: 'center',
          },
        }],
        polar: {
          radius: ['95%', '75%'],
          center: ['52%', '50%'],
        },
        angleAxis: {
          max: 100,
          show: false,
        },
        radiusAxis: {
          type: 'category',
          show: true,
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,

          },
          axisTick: {
            show: false
          },
        },
        series: [
          {
            name: '',
            type: 'bar',
            roundCap: true,
            barWidth: 60,
            showBackground: true,
            backgroundStyle: {
              color: '#2e2856',
              opacity: "0"
            },
            data: [this.governRate],
            coordinateSystem: 'polar',
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0.5, 1, 0.5, [{
                  offset: 0,
                  color: '#1D43F3'
                }, {
                  offset: 1,
                  color: '#4366F3'
                }]),
              }
            }
          },

        ]
      };
      option && chart.setOption(option)
      window.addEventListener("resize", function () {
        chart.resize();
      })
    },
    drawPieHugerate() {
      const chart = this.$echarts.init(document.getElementById('Huge'))
      // 绘制图表
      const option = {
        title: [{
          text: `${this.HugeRate}%`,
          x: '43%',
          y: '40%',
          textAlign: 'center',
          textStyle: {
            fontSize: '16',
            fontWeight: '400',
            color: '#FFFFFF',
            textAlign: 'center',
          },
        }],
        polar: {
          radius: ['95%', '75%'],
          center: ['52%', '50%'],
        },
        angleAxis: {
          max: 100,
          show: false,
        },
        radiusAxis: {
          type: 'category',
          show: true,
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,

          },
          axisTick: {
            show: false
          },
        },
        series: [
          {
            name: '',
            type: 'bar',
            roundCap: true,
            barWidth: 60,
            showBackground: true,
            backgroundStyle: {
              color: '#2e2856',
              opacity: "0"
            },
            data: [this.HugeRate],
            coordinateSystem: 'polar',
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0.5, 1, 0.5, [{
                  offset: 0,
                  color: '#BB42EF'
                }, {
                  offset: 1,
                  color: '#E23AF5'
                }]),
              }
            }
          },

        ]
      };
      option && chart.setOption(option)
      window.addEventListener("resize", function () {
        chart.resize();
      })
    },
    drawPieArate() {
      const chart = this.$echarts.init(document.getElementById('ARated'))
      // 绘制图表
      const option = {
        title: [{
          text: `${this.ARate}%`,
          x: '43%',
          y: '40%',
          textAlign: 'center',
          textStyle: {
            fontSize: '16',
            fontWeight: '400',
            color: '#FFFFFF',
            textAlign: 'center',
          },
        }],
        polar: {
          radius: ['95%', '75%'],
          center: ['52%', '50%'],
        },
        angleAxis: {
          max: 100,
          show: false,
        },
        radiusAxis: {
          type: 'category',
          show: true,
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,

          },
          axisTick: {
            show: false
          },
        },
        series: [
          {
            name: '',
            type: 'bar',
            roundCap: true,
            barWidth: 60,
            showBackground: true,
            backgroundStyle: {
              color: '#2e2856',
              opacity: "0"
            },
            data: [this.ARate],
            coordinateSystem: 'polar',
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0.5, 1, 0.5, [{
                  offset: 0,
                  color: '#6CCEE6'
                }, {
                  offset: 1,
                  color: '#6FCCE5'
                }]),
              }
            }
          },
        ]
      };
      option && chart.setOption(option)
      window.addEventListener("resize", function () {
        chart.resize();
      })
    },


  }
}
</script>
<style lang="scss" scoped>
.chart {
  display: flex;
  justify-content: space-between;
  padding: 15px 47px 0 47px;

  .item {
    width: 125px;
    display: flex;
    flex-direction: column;

    #governReception,
    #Huge,
    #ARated {
      width: 125px;
      height: 125px;
      margin-bottom: 5px;
    }

    div {
      text-align: center;
      color: #FFFFFF;
      font-size: 16px;
      margin-top: 5px;

      span {
        color: #28D9FA;
      }
    }
  }

}
</style>