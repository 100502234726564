<template>
  <div class='right_top'>
    <div class="city_title cup"></div>
    <chart ref="charts"/>
    <div class="lineBox"><div class="line"></div></div>
    <div class="baseNum">
      <div class="item">
        <img src="../../../../assets/city/room.png" alt="">
        <div class="itemText">
          <div>{{ itemMsg.roomSum}}</div>
          <div>房间数量</div>
        </div>
      </div>
      <div class="item">
        <img src="../../../../assets/city/bj_room.png" alt="">
        <div class="itemText">
          <div>{{ totalData.roomCount }}</div>
          <div>洗消间数量</div>
        </div>
      </div>
      <div class="item">
        <img src="../../../../assets/city/straff.png" alt="">
        <div class="itemText">
          <div>{{ cleanerCount }}</div>
          <div>保洁员数量</div>
        </div>
      </div>
      <div class="item">
        <img src="../../../../assets/city/dev.png" alt="">
        <div class="itemText">
          <div>{{ itemMsg.sanitaryWareRfidDeviceSum }}</div>
          <div>读卡器数量</div>
        </div>
      </div>
      <div class="item">
        <img src="../../../../assets/city/bc.png" alt="">
        <div class="itemText">
          <div>{{ itemMsg.textileSum }}</div>
          <div>布草数量</div>
        </div>
      </div>
      <div class="item">
        <img src="../../../../assets/city/jj_cloth.png" alt="">
        <div class="itemText">
          <div>{{ itemMsg.clothSum }}</div>
          <div>抹布数量</div>
        </div>
      </div>
    </div>
    <div class="lineBox"><div class="line"></div></div>
    <div class="useNum">
      <el-row>
        <el-col :span="12">本周房间打扫数量：<span>{{itemMsg.braceletRoomSum  }}</span></el-col>
        <el-col :span="12"></el-col>
      </el-row>
      <el-row>
        <el-col :span="12">本周布草送出数量：<span>{{ itemMsg.textileOutSum }}</span></el-col>
        <el-col :span="12">本周布草送入数量：<span>{{ itemMsg.textileInSum }}</span></el-col>
      </el-row>
      <el-row>
        <el-col :span="12">本周消毒柜时长(h)：<span>{{ bottomData.runtime ? parseInt(bottomData.runtime / 3600) : 0 }}</span></el-col>
        <el-col :span="12">本周人员时长(h)：<span>{{ bottomData.worktime ? parseInt(bottomData.worktime / 3600) : 0 }}</span></el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getRight, getCup,getCleaner } from '@/api'
import chart from './chart.vue'

export default {
  components:{chart},
  data () {
      return {
      itemMsg: {},
      cleanerCount:null,
      totalData: {
        areaCount: 0,
        boardCount: 0,
        cameraCount: 0,
        roomCount: 0,
        siteCount: 0,
        visitorCount: 0
      },
      bottomData: {}
    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  methods: {
    init () {
      getRight().then(res => {
        this.itemMsg = res.data
      })
      // getCountStatistic().then(res => {
      //   this.totalData = res.data
      // })
      getCup().then(res => {
        this.bottomData = res.data
      })
      getCleaner().then(res=>{
        this.cleanerCount=res.data
      })
    },
    gettotalData(val){
      this.totalData =val
    },
    initCharts(val){
      this.$refs.charts.init(val)

    }
  }
};
</script>

<style scoped lang="scss">
.right_top {
  width: 607px;
  height: 629px;
  background: url("../../../../assets/city/lefttop.png") no-repeat;
  background-size: 100% 100%;
  .cup {
    background: url("../../../../assets/city/cup_title.png") no-repeat;
    height: 33px;
    background-size: 100%;
  }
  .lineBox{
    padding-left: 27px;
    .line{
      background: url('~@/assets/city/line.png') no-repeat;
      height: 25px;
      background-size:100%;
    }
  }
  .baseNum{
    padding: 20px  47px 0;
    color: #ffffff;
    display: flex;
    font-size: 16px;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 150px;
    .item{
      width: 30%;
      display: flex;
      align-items: center;
      img{
        width:41px ;
        height: 37px;
        margin-right: 20px;
      }
      .itemText{
        div:first-child{
          font-size: 28px;
          margin-bottom: 8px;
        }
      }
      &:nth-child(2){
        img{
          width:44px ;
          height:39px;
        }
      }
      &:nth-child(3){
        img{
          width:34px ;
          height:39px;
        }
      }
      &:nth-child(4){
        img{
          width:48px ;
          height:31px;
        }
      }
      &:nth-child(5){
        img{
          width:43px ;
          height:32px;
        }
      }
      &:last-child{
        img{
          width: 41px;
          height: 39px;
        }
      }
    }
  }
  .useNum{
    color: #ffffff;
    padding: 0 47px;
    .el-row{
      font-size:16px;
      color: #FDFDFD;
      margin-top: 15px;
      .el-col-12{
        display: flex;
        align-items: center;
        span{
          font-size: 28px;
          color:#44CAFF ;
        }
      }
    }
    
  }
}
</style>
