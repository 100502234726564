<template>
  <div class="pie">
    <div id="pieChart"  ref="chart"></div>
    <div class="bg"></div>
  </div>
</template>
<script>
import { getWorkTotal } from '@/api'
import {getPie3D}from '@/utils/pie'

const color = ['#E9A5FF', '#3afce3']
export default{
  data(){
    return{
      itemMsg:{},
      optionData:[
        {name:'转执法',value:null},
        {name:'解除风险',value:null}
      ],
      optionTotal:[
       { name:'风险推送事件',value:500}
      ],
      statusChart:null
    }
  },
  computed:{},
  watch:{
    itemMsg:{
      handler(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          const that = this;
          that.setLabel();
          that.initChart();
          window.onresize = function() {
            that.changeSize();
          };
      }
    },
    deep: true
    }
  },
  created(){
    this.init()
  },
  mounted(){
    
  },
  methods:{
    init () {
      const start = this.$moment().subtract(1, 'week').startOf('week').format("YYYY-MM-DD");
      const stop = this.$moment().subtract(1, 'week').endOf('week').format("YYYY-MM-DD");

      getWorkTotal(start, stop).then(res => {
        // console.log(res);
        this.optionData[0].value=res.data.law
        this.optionData[1].value=res.data.relieve
        this.optionTotal[0].value=res.data.total
        // this.optionData[0].value=300
        // this.optionData[1].value=836
        this.itemMsg=res.data
        this.$emit('data',res.data)
      })
    },
    // 初始化label样式
    setLabel () {
      if (this.optionData[0].value!=0||this.optionData[1].value!=0) {
        this.optionData.forEach((item, index) => {
        item.itemStyle = {
          color: color[index]
        }
        item.label = {
          normal: {
            padding: [0, -40],
            overflow:'none',
            show: item.value==0?false: true,
            color: color[index],
            formatter: [
              '{d|{d}%} \n'
            ].join('\n'), // 用\n来换行
            rich: {
              d: {
                color: color[index],
                align: 'left',
                fontSize:10,
                fontWeight:'normal',
              }
            },
            
          }
        }
        item.labelLine = {
          normal: {
            lineStyle: {
              width: 1,
              color:color[index],
            },
            length: 20,
            length2: 35,
          },
        }
      })
      }else{
        this.optionTotal.forEach((item, index) => {
        item.itemStyle = {
          color:'#44CAFF'
        }
        item.label = {
          normal: {
            padding: [0, -40],
            overflow:'none',
            show: item.value==0?false: true,
            color: '#44CAFF',
            formatter: [
              '{d|{d}%} \n'
            ].join('\n'), // 用\n来换行
            rich: {
              d: {
                color: '#44CAFF',
                align: 'left',
                fontSize:10,
                fontWeight:'normal',
              }
            },
            
          }
        }
        item.labelLine = {
          normal: {
            lineStyle: {
              width: 1,
              color:color[index],
            },
            length: 20,
            length2: 35,
          },
        }
      })
      }
      
    },
    // 图表初始化
    initChart () {
      if (this.statusChart==null) {
        this.statusChart = this.$echarts.init(this.$refs.chart)
      }
      
      // 传入数据生成 option, 构建3d饼状图, 参数工具文件已经备注的很详细
      if (this.optionData[0].value!=0||this.optionData[1].value!=0) {
        this.option = getPie3D(this.optionData, 0, 330, 47, 10, 0.5)
        this.statusChart.setOption(this.option)
      // 是否需要label指引线，如果要就添加一个透明的2d饼状图并调整角度使得labelLine和3d的饼状图对齐，并再次setOption
      this.option.series.push({
        name: '', //自己根据场景修改
        backgroundColor: 'transparent',
        type: 'pie',
        label: {
          opacity: 1,
          fontSize: 13,
        },
        startAngle: -5, // 起始角度，支持范围[0, 360]。
        clockwise: false, // 饼图的扇区是否是顺时针排布。上述这两项配置主要是为了对齐3d的样式
        radius: ['20%', '50%'],
        center: ['50%', '50%'],
        data: this.optionData,
        itemStyle: {
          opacity: 0  //这里必须是0，不然2d的图会覆盖在表面
        }
      })
      this.statusChart.setOption(this.option)
      }else{
        this.option = getPie3D(this.optionTotal, 0, 330, 47, 10, 0.5)
              this.statusChart.setOption(this.option)
      // 是否需要label指引线，如果要就添加一个透明的2d饼状图并调整角度使得labelLine和3d的饼状图对齐，并再次setOption
      this.option.series.push({
        name: '', //自己根据场景修改
        backgroundColor: 'transparent',
        type: 'pie',
        label: {
          opacity: 1,
          fontSize: 13,
          show:false
        },
        startAngle: -5, // 起始角度，支持范围[0, 360]。
        clockwise: false, // 饼图的扇区是否是顺时针排布。上述这两项配置主要是为了对齐3d的样式
        radius: ['20%', '50%'],
        center: ['50%', '50%'],
        data: this.optionData,
        itemStyle: {
          opacity: 0  //这里必须是0，不然2d的图会覆盖在表面
        }
      })
      this.statusChart.setOption(this.option)
      }
      

    },

    // 自适应宽高
    changeSize () {
      this.statusChart.resize()
   }
  },
}
</script>
<style lang="scss" scoped>
.pie{
  position: relative;
  width:300px;
  height: 230px;
  background: url('~@/assets/city/water.png') no-repeat;
  background-size: 269px 217px;
 
  #pieChart{
    position: absolute;
    top:-10% ;
    left: 50%;
    transform: translate(-55%);
    width: 300px;
    height: 230px;
    // z-index: 12;
  }
  // .bg {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   // z-index: -1;
  //   width: 269px;
  //   height:271px;
  //   background: no-repeat center;
  //   background: url('~@/assets/city/water.png') no-repeat;
  //   background-size: 100% 100%;
  // }
}
</style>
